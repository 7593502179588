<template>
  <div class="article_list">
    <div class="head_section">
      <h2 id="dkBody" class="tit_section">진행중 문서함</h2>
    </div>
    <search-div
      title-search="검색"
      title-date="상신일시"
      :is-detail-page="true"
      :is-approval="true"
      :is-complete="false"
      @init-search-form="initSearchForm"
      @select-connector="selectConnector"
    >
      <!-- :open-search-box="true" -->
    </search-div>
    <list-div
      ref="docList"
      :aprvl-status="approvalStatus.NONE"
      :doc-status="docStatus.PROCESSING"
      :open-doc-no="true"
      :open-draft-date="true"
      :open-approval-line="true"
      :is-approval="true"
      :doc-flag="'RI'"
      :doc-list="docList"
      :total-page="totalPage"
      :listLineDatas="listLineDatas"
      @go-detail="goDetail"
      @get-doc-list="getDocList"
    />
  </div>
</template>
<script>
import SearchDiv from "@/_approval/components/shared/list/SearchDiv";
import ListDiv from "@/_approval/components/shared/list/ListDiv";
import commDocs from "@/_approval/mixins/common/commDocs";
import { approvalStatus, docStatus } from "@/_approval/const/const";
import ApiService from "@/services/ApiService";

export default {
  name: "CcIngDocs",
  components: { ListDiv, SearchDiv },
  mixins: [commDocs],
  data() {
    return {
      searchForm: {},
      totalPage: 0,
      currentPage: 0,
      docList: [],
      approvalStatus: approvalStatus,
      docStatus: docStatus,

      listLineDatas: [
        { colStyle: "width: 56px", key: "checkbox" },
        { text: "문서번호", colStyle: "width: 180px", key: "docNo" },
        { text: "구분", colStyle: "width: 120px", key: "targetGubun" },
        {
          text: "제목",
          colStyle: "",
          key: "docTitle",
          class: "cell_left td_ellip",
        },
        {
          text: "구매요청자",
          colStyle: "width: 152px",
          key: "drftAccountId",
          key2: "drftEmpName",
        },
        {
          text: "구매요청부서",
          colStyle: "width: 96px",
          key: "drftDeptName",
          class: "td_ellip",
        },
        {
          text: "결재대기자",
          colStyle: "width: 152px",
          key: "nextAprvlAccountId",
          key2: "nextAprvlEmpName",
        },
        { text: "상신일시", colStyle: "width: 132px", key: "drftDate" },
      ],
    };
  },
  methods: {
    selectConnector(formData) {
      this.searchForm = formData;
      localStorage.searchForm = JSON.stringify(this.searchForm);
      this.$refs.docList.resetSort(1); // 서칭시 페이지 초기화로 reset
    },
    initSearchForm(formData) {
      this.searchForm = formData;
    },
    async getDocList(pagingConfig, sort) {
      const params = this.makeBaseDocListParams(pagingConfig, this.searchForm);
      params.docStatus = docStatus.PROCESSING;
      params.aprvlStatus = approvalStatus.NONE;

      const res = await ApiService.shared.get(`${this.$apiPath.APRVL_DOC_LIST}`, {
        params: params,
      });
      if (!this.checkNormalResponse(res)) {
        return;
      }

      if (res.data && res.data.length > 0) {
        res.data.forEach((e) => (e.regDate = this.strDateTimeSplit(e.regDate)));
      }

      this.totalPage = parseInt(res.total);
      this.docList = res.data;
    },
    goDetail(docNo) {
      //this.$router.push({ path: this.routerPath.PAYMENT_CC_ING_DETAIL, query: { docNo: docNo } });
    },
  },
};
</script>
